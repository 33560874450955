import { Link } from "gatsby"
import React from "react"
import { graphql, useStaticQuery } from 'gatsby';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';


const About = () => {
    const { realEstate } = useStaticQuery(graphql`
            query {
                realEstate {
                    sections {
                        home {
                          about {
                            title
                            paragraph
                            image
                            buttons {
                              link
                              value
                            }
                            items {
                              paragraph
                              value
                            }
                          }
                        }
                        about {
                            image
                        }
                    }
                }
    }`)

    return realEstate.sections && (
        <section class={"about-us pt-5 mt-lg-0 mt-lg-0 " + (realEstate.sections.home.about.items[0].value === '' ? 'height-min' : '')} style={{backgroundImage:"url(" + realEstate.sections.about.image + ""}}>
            <div className="opacity"></div>
            <div className="container-fluid py-lg-5">
                <div className="row">
                    <div className="col-lg-2">
                        <p className="section-name">{realEstate.sections.home.about.title}</p>
                    </div>
                    <div className="col-lg-10">
                        <h2 className="sub-section">{realEstate.sections.home.about.paragraph.split(".")[0]}.</h2>
                        {/* <h2>SOMOS UN EQUIPO PROFESIONAL, MULTIDISCIPLINARIO,<br className="d-none d-lg-block" /> EMPÁTICO Y RESOLUTIVO EN EL MUNDO DEL REAL ESTATE.</h2> */}
                    </div>
                    <div className="col-10 mt-lg-5 offset-lg-2">
                        <Link to={realEstate.sections.home.about.buttons[0].link} className="btn btn-white">{realEstate.sections.home.about.buttons[0].value}</Link>
                    </div>
                    {realEstate.sections.home.about.items.length > 1 &&
                        <div class={"col-lg-10 offset-lg-2 pt-5 mt-5 " + (realEstate.sections.home.about.items[0].value === '' ? 'd-none' : '')}>
                            <div className="row py-lg-5">
                                {realEstate.sections.home.about.items.map((item, index) => (
                                    // index > 1 ?
                                        index % 2 === 0 ?
                                        <div class={"col-lg-6 pr-lg-4 "}>
                                            <div className="item">
                                                <div className="row no-gutters">
                                                    <div className="col-6 col-lg-6 text-end">
                                                        <h1 className="hyper d-flex justify-content-end">+
                                                            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                                                {({ isVisible }) => (
                                                                <span className="counter-value one">
                                                                    {isVisible ? <CountUp end={item.value} /> : null}
                                                                </span>
                                                                )}
                                                            </VisibilitySensor>
                                                        </h1>
                                                    </div>
                                                    <div className="col-6 col-lg-6 px-3">
                                                        <p>{item.paragraph}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-lg-6">
                                            <div className="item" id="counter">
                                                <div className="row no-gutters">
                                                    <div className="col-6 col-lg-7 text-end">
                                                        <h1 className="hyper d-flex justify-content-end">+
                                                            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                                                {({ isVisible }) => (
                                                                <span className="counter-value one">
                                                                    {isVisible ? <CountUp end={item.value} /> : null}
                                                                </span>
                                                                )}
                                                            </VisibilitySensor>
                                                        </h1>
                                                    </div>
                                                    <div className="col-6 col-lg-5 px-3">
                                                        <p>{item.paragraph}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                ))}
                            </div>
                        </div>
                }
                </div>

            </div>
        </section>
    )
}

export default About