import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import loadable from "@loadable/component"

const ClientSideOnlyLazy = React.lazy(() =>
  import("../components/Home/properties")
)

//Components
import Main from "../components/Home/main"
import About from "../components/Home/about"
const Properties = loadable(() => import("../components/Home/properties"))
const Developments = loadable(() => import("../components/Home/developments"))
const Novelties = loadable(() => import("../components/Home/novelties"))
import Investors from "../components/investors"

const IndexPage = () => {
  const isSSR = typeof window === "undefined"

  return (
    <Layout>
      <Seo />
      <Main />
      <Developments />
      <Properties />
      <About />
      <Investors />
      <Novelties />
    </Layout>
  )
}

export default IndexPage
